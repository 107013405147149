<template>
  <div v-if="form.formElements" >
    <div
      v-for="(e, i) in form.formElements"
      :key="i"
      style="margin-bottom: 20px;"
    >
      <template v-if="shouldRender(e)" >
        <p
          v-if="shouldPrintBlock(e)"
          style="font-weight: 700; font-size: 18px;"
        >
          {{ e.block_info.name }}
        </p>
        <component :is="getComponent(e.alias)" :element="e" />
      </template>
    </div>
  </div>
</template>

<script>

import Check from '@/views/forms/components/Check.vue'
import TextInput from '@/views/forms/components/TextInput.vue'
import Sign from '@/views/forms/components/Sign.vue'
import Archive from '@/views/forms/components/Archive.vue'
import TextBlock from '@/views/forms/components/TextBlock.vue'
import TextArea from '@/views/forms/components/TextArea.vue'

export default {
  components: {
    Check,
    TextInput,
    Sign,
    Archive,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      printedBlocks: new Set(),
    }
  },
  methods: {
    shouldRender(element) {
      return ['check', 'text_input', 'signature', 'archive', 'text_area', 'text_block'].includes(element.alias)
    },
    shouldPrintBlock(element) {
      // Verificar si el bloque ya se ha impreso anteriormente
      if (this.printedBlocks.has(element.block_info.id)) {
        return false
      } else {
        this.printedBlocks.add(element.block_info.id)
        return true
      }
    },
    getComponent(alias) {
      switch (alias) {
        case 'check':
          return Check
        case 'text_input':
          return TextInput
        case 'signature':
          return Sign
        case 'archive':
          return Archive
        case 'text_block':
          return TextBlock
        case 'text_area':
          return TextArea
        default:
          return null
      }
    },
  },
}
</script>
