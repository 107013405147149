<template>
  <div>
    <p>{{ element.config.question }}</p>
    <b-form-input/>
  </div>
</template>

<script>

import {
  BFormInput,
} from 'bootstrap-vue'
export default {
  components: {
    BFormInput,
  },
  props: {
    element: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
