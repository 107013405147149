<template>
  <div style="margin-bottom: 20px;">
    <p>{{ element.config.question }}</p>
    <div v-html="element.config.text"></div>
  </div>
</template>

<script>

export default {
  props: {
    element: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
