<template>
  <div>
    <b-button
      variant="primary"
      block
    >
      {{ element.name }}
    </b-button>
  </div>
</template>

<script>

import {
  BButton,
} from 'bootstrap-vue'
export default {
  components: {
    BButton,
  },
  props: {
    element: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
