<template>
  <div>
    <p>{{ element.config.question }}</p>
    <b-form-textarea/>
  </div>
</template>

<script>

import { BFormTextarea } from 'bootstrap-vue'

export default {
  components: { BFormTextarea },
  props: {
    element: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
